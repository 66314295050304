import styles from "../styles/prizes.module.css"
import bg from "../assets/Backgrounds/prizesBG.svg"
import goldGem from "../assets/goldGem.svg"

const Prizes = () => {
    return (
        <div className={styles.main}>
            <div className={styles.bg}>
                <img src={bg} alt="" />
            </div>
            <div className={styles.content}>

                <div className={styles.prizeContainer}>
                    <h1>Prizes</h1>
                    <div className={styles.cardContainer}>
                        <div className={styles.card}>
                            <img src={goldGem} alt="" />
                            <div>
                                <p>Circular Economy & Sustainable Living</p>
                                <h3>2 x $800 team cash prize</h3>
                            </div>
                        </div>
                        <div className={styles.card}>
                            <img src={goldGem} alt="" />
                            <div>
                                <p>Inclusivity & Accessibility Techs</p>
                                <h3>2 x $800 team cash prize</h3>
                            </div>
                        </div>
                        <div className={styles.card}>
                            <img src={goldGem} alt="" />
                            <div>
                                <p>AI-enabled Internet-of-Things</p>
                                <h3>2 x $800 team cash prize</h3>
                            </div>
                        </div>
                        <div className={styles.card}>
                            <img src={goldGem} alt="" />
                            <div>
                                <p>Financial Technologies</p>
                                <h3>2 x $800 team cash prize</h3>
                            </div>
                        </div>
                        
                    </div>


                </div>
                <div className={styles.benefitsContainer}>
                    <h1>Participant Benefits</h1>
                    <div className={styles.subcontainer}>
                        <h2>Makerspace Access</h2>
                        <ul>
                            <li>3D Printer</li>
                            <li>Laser Cutter</li>
                            <li>Acrylic Benders</li>
                            <li>Bandsaw</li>
                            <li>Drill Press</li>
                            <li>Electronics Lab</li>
                        </ul>
                    </div>
                    <div className={styles.subcontainer}>
                        <h2>Hackpack</h2>
                        <ul>
                            <li>ESP32-S3</li>
                            <li>RFID module</li>
                            <li>Character LCDs</li>
                            <li>7-Segment Displays</li>
                            <li>Infared Remotes</li>
                            <li>Carrying Case</li>
                            <li>...and more!</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Prizes;
