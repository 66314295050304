import styles from "../styles/navbar.module.css"
import smallLogo from "../assets/smallLogo.png"

const NavBar = ({
    aboutRef,
    prizesRef,
    scheduleRef,
    sponsorsRef,
    faqRef,
    bannerRef,
}) => {

    const handleScroll = (refPoint) => {
        switch (refPoint) {
            case "banner":
                bannerRef.current.scrollIntoView();
                break;
            case "about":
                aboutRef.current.scrollIntoView();
                break;
            case "prizes":
                prizesRef.current.scrollIntoView();
                break;
            case "schedule":
                scheduleRef.current.scrollIntoView();
                break;
            case "sponsors":
                sponsorsRef.current.scrollIntoView();
                break;
            case "faq":
                faqRef.current.scrollIntoView();
                break;
            default:
                bannerRef.current.scrollIntoView();
        }
    }

    return (
        <div className={styles.main}>
            <div className={styles.left}>
                <img src={smallLogo} alt="Back to home page" onClick={() => handleScroll("banner")} />
            </div>
            <div className={styles.right}>
                <ul>
                    <li className={styles.item} onClick={() => handleScroll("about")}>ABOUT</li>
                    <li className={styles.item} onClick={() => handleScroll("prizes")}>PRIZES</li>
                    <li className={styles.item} onClick={() => handleScroll("schedule")}>SCHEDULE</li>
                    <li className={styles.item} onClick={() => handleScroll("sponsors")}>SPONSORS</li>
                    <li className={styles.item} onClick={() => handleScroll("faq")}>FAQ</li>
                </ul>
            </div>
        </div>
    );
}

export default NavBar;