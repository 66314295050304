import { useEffect, useState } from "react";
import styles from "../styles/countdown.module.css"


const Countdown = () => {
    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

   /* const eventDate = "August, 26, 2023";

    const getTime = () => {
        const timeLeft = Date.parse(eventDate) - Date.now();
        setDays(Math.floor(timeLeft / (1000 * 60 * 60 * 24)));
        setHours(Math.floor((timeLeft / (1000 * 60 * 60)) % 24));
        setMinutes(Math.floor((timeLeft / 1000 / 60) % 60));
        setSeconds(Math.floor((timeLeft / 1000) % 60));
    }

    useEffect(() => {
        const interval = setInterval(() => getTime(), 1000)
        return () => clearInterval(interval);
    }, []);*/

    return (
        <div className={styles.main}>
            <p className={styles.note}>System Booted!</p>
            <div className={styles.container}>
                <div className={styles.section}>
                    <h1>{(days < 10) ? 0 : ""}{days}</h1>
                    <p>Days</p>
                </div>
                <div className={styles.section}>
                    <h1>{(hours < 10) ? 0 : ""}{hours}</h1>
                    <p>Hours</p>
                </div>
                <div className={styles.section}>
                    <h1>{(minutes < 10) ? 0 : ""}{minutes}</h1>
                    <p>Minutes</p>
                </div>
                <div className={styles.section}>
                    <h1>{(seconds < 10) ? 0 : ""}{seconds}</h1>
                    <p>Seconds</p>
                </div>
            </div>

        </div>
    );
}

export default Countdown;