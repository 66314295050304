import styles from "../styles/contact.module.css"
import bg from "../assets/Backgrounds/contactBG.mp4"
import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import ContactModal from "./ContactModal";


const Contact = () => {
    const form = useRef();
    const [openModal, setOpenModal] = useState(false);
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const sendEmail = (e) => {
        e.preventDefault();
        
        emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, form.current, process.env.REACT_APP_PUBLIC_KEY)
          .then((result) => {
              console.log(result.text);
              setErrorMsg("");
              setError(false);
              setOpenModal(true);

          }, (error) => {
              console.log(error.text);
              setError(true);
              setError(error.text);
              setOpenModal(true);
          });

          e.target.reset()
      };

    return (
        <div className={styles.main}>
            <div className={styles.bg}>
                <video  autoPlay loop muted>
                <source src={bg} type='video/mp4' />
                </video>
            </div>
            <div className={styles.card}>
                <h1>Contact Us</h1>
                <form ref={form} onSubmit={(e) => sendEmail(e)}>
                    <div className={styles.formItem}>
                        <label>Your Name*</label>
                        <input type="text" placeholder="Your cool name :)" name="user_name" required/>
                    </div>
                    <div className={styles.formItem}>
                        <label>Your Email*</label>
                        <input type="email" placeholder="No spam, promise!" name="user_email" required/>
                    </div>
                    <div className={styles.formItem}>
                        <label>What would you like to talk to us about?*</label>
                        <textarea required name="message" type="text" placeholder="Anything is fine! Your questions, suggestions, feedback etc." />
                    </div>
                    <button type="submit">Send</button>
                </form>
            </div>
        <ContactModal {...{openModal, setOpenModal, error, errorMsg}}/>
        </div>
    );
}

export default Contact;