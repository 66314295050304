import { useEffect, useState } from "react";
import styles from "../styles/carousel.module.css"

const Carousel = () => {
    const [counter, setCounter] = useState(1);
    const [pause, setPause] = useState(false);
    const projects = [
        {
            desc: "A seriously-cool plasma ion rocket thruster that uses high currents and pressures to create thrust via the dissociation of argon electrons.",
            team: "Team Duct Tape Hax, Best Space Hack WTH 2017"
        },
        {
            desc: "A magical wand powered by AR that unlocks the barriers of your phone's screen so that you can create, collaborate and transform the world by drawing.",
            team: "Team AR Wand, Best Overall Hack WTH 2018"
        },
        {
            desc: "A robotic turtle which travels the ocean, filtering microplastics as it swims, and a web app that livestreams its dives in order to raise awareness of the amount of plastics removed",
            team: "Team WhaleFood, Best Design Hack WTH 2021"
        },
        {
            desc: "A recycling claw machine that allows you relive your childhood while doing your part to protect our planet.",
            team: "Team Rubber Duck, Best Sustainable and Smart City Hack WTH 2022"
        }]

    const handleNext = () => {
        if (counter !== projects.length) {
            setCounter(counter + 1);
        } else {
            setCounter(1);
        }
    };



    const handlePage = (page) => {
        setCounter(page);
        console.log(page)
    };

    const handleMouse = () => {
        setPause(!pause);
    };

    useEffect(() => {
        let interval = setInterval(() => {
            if (!pause) {
                handleNext();
            } else {
                clearInterval(interval);
            }
        }, 8000);
        return () => clearInterval(interval);
    });

    return (
        <div className={styles.main}>
            <div
                className={styles.slide}
                onMouseEnter={handleMouse}
                onMouseLeave={handleMouse}
            >
                {projects.map((proj, i) => (
                    <div className={counter - 1 === i ? styles.show : styles.hidden} key={i}>
                        <h3>"{proj["desc"]}"</h3>
                        <p>{proj["team"]}</p>
                    </div>
                ))}

            </div>
            <div className={styles.page}>
                {projects.map((proj, i) => (
                    <span
                        key={i}
                        className={counter - 1 === i ? `${styles.dot} ${styles.active}` : styles.dot}
                        onClick={() => handlePage(i + 1)}
                    />
                ))}
            </div>
        </div>
    );
}

export default Carousel;