import styles from "../styles/footer.module.css"
import logo from "../assets/smallLogoWhite.png"


const Footer = () => {
    return ( 
        <div className={styles.main}>
            <div className={styles.wrapper}>
                <img src={logo} alt=""/>
                <p className={styles.email}>Email: <a href="mailto:sutdwth@gmail.com">sutdwth@gmail.com</a></p>
            </div>
            <div className={styles.wrapper}>
                <p>Copyright © 2023 What The Hack. All rights reserved.</p>
                <div className={styles.container}>
                    <a href="https://bit.ly/wth2023-terms" target="_blank" rel="noreferrer">SUTD</a>
                    <a href="https://bit.ly/wth2023-terms" target="_blank" rel="noreferrer">Terms and Conditions</a>
                    <a href="https://github.com/wthdevelopers/website-2023" target="_blank" rel="noreferrer">Source Code</a>
                </div>
            </div>
        </div>
     );
}
 
export default Footer;