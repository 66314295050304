import styles from "../styles/schedule.module.css"

const Schedule = () => {
    return (
        <div className={styles.main}>
            <h1>Schedule</h1>
            <h4>Lot's of snacks will be provided!</h4>
            <div className={styles.callout}>
                <h3>- 24th-25th August -</h3>
                <p>Optional IOT Workshop by Espressif</p>
            </div>
            <div className={styles.wrapper}>
                <div className={styles.container}>
                    <h2><span>-</span> Day 1 <span>-</span></h2>
                    <ul className={styles.timeline}>
                        <li>
                            <div className={styles.card}>
                                <h4>09:00</h4>
                                <p>Registration</p>
                            </div>
                        </li>
                        <li>
                            <div className={styles.card}>
                                <h4>10:00</h4>
                                <p>Opening Briefing & Keynote Speech</p>
                            </div>
                        </li>
                        <li>
                            <div className={styles.card}>
                                <h4>11:00</h4>
                                <p>Lunch & Hacking Starts</p>
                            </div>
                        </li>
                        <li>
                            <div className={styles.card}>
                                <h4>12:00</h4>
                                <p>Fireside Chats & Human Library</p>
                            </div>
                        </li>
                        <li>
                            <div className={styles.card}>
                                <h4>16:00</h4>
                                <p>Dinner</p>
                            </div>
                        </li>
                        <li>
                            <div className={styles.card}>
                                <h4>22:00</h4>
                                <p>Hacking Spaces Close</p>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className={`${styles.container} ${styles.container2}`}>
                    <h2><span>-</span> Day 2 <span>-</span></h2>
                    <ul className={`${styles.timeline} ${styles.timeline2}`}>
                        <li>
                            <div className={styles.card}>
                                <h4>8:30</h4>
                                <p>Hacking Spaces Reopen & Breakfast</p>
                            </div>
                        </li>
                        <li>
                            <div className={styles.card}>
                                <h4>12:00</h4>
                                <p>Lunch</p>
                            </div>
                        </li>
                        <li>
                            <div className={styles.card}>
                                <h4>13:00</h4>
                                <p>Hacking Ends & Judging Round 1 Starts</p>
                            </div>
                        </li>
                        <li>
                            <div className={styles.card}>
                                <h4>15:30</h4>
                                <p>Judging Round 2 Starts</p>
                            </div>
                        </li>
                        <li>
                            <div className={styles.card}>
                                <h4>15:15</h4>
                                <p>Exhibition and Judging</p>
                            </div>
                        </li>
                        <li>
                            <div className={styles.card}>
                                <h4>17:00</h4>
                                <p>Prize Presentation</p>
                            </div>
                        </li>
                        <li>
                            <div className={styles.card}>
                                <h4>17:30</h4>
                                <p>Networking & Dinner</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>


        </div>
    );
}

export default Schedule;