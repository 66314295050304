import styles from "../styles/navbarmobile.module.css"
import smallLogo from "../assets/smallLogo.png"
import { FaBars, FaTimes } from "react-icons/fa";
import { useState } from "react";



const NavBarMobile = ({
    aboutRef,
    prizesRef,
    scheduleRef,
    sponsorsRef,
    faqRef,
    bannerRef,
}) => {

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleScroll = (refPoint) => {
        setIsMenuOpen(false);
        switch (refPoint) {
            case "banner":
                bannerRef.current.scrollIntoView();
                break;
            case "about":
                aboutRef.current.scrollIntoView();
                break;
            case "prizes":
                prizesRef.current.scrollIntoView();
                break;
            case "schedule":
                scheduleRef.current.scrollIntoView();
                break;
            case "sponsors":
                sponsorsRef.current.scrollIntoView();
                break;
            case "faq":
                faqRef.current.scrollIntoView();
                break;
            default:
                bannerRef.current.scrollIntoView();
        }
    }
    return (
        <>
            <div className={styles.menu} style={(isMenuOpen) ? { width: "50vw", padding: "12px 18px" } : { width: "0px", padding: "12px 0" }}>
                <button onClick={() => setIsMenuOpen(false)}><FaTimes /></button>
                <ul>
                    <li className={styles.item} onClick={() => handleScroll("about")}>ABOUT</li>
                    <li className={styles.item} onClick={() => handleScroll("prizes")}>PRIZES</li>
                    <li className={styles.item} onClick={() => handleScroll("schedule")}>SCHEDULE</li>
                    <li className={styles.item} onClick={() => handleScroll("sponsors")}>SPONSORS</li>
                    <li className={styles.item} onClick={() => handleScroll("faq")}>FAQ</li>
                </ul>
            </div>
            <div className={styles.main}>
                <div className={styles.left}>
                    <img src={smallLogo} alt="Back to home page" onClick={() => handleScroll("banner")} />
                </div>
                <div className={styles.right}>
                    <button onClick={() => setIsMenuOpen(true)}><FaBars className={styles.menuIcon} /></button>
                </div>
            </div></>

    );
}

export default NavBarMobile;