import styles from "../styles/pastWTH.module.css"
import Carousel from "./Carousel";
import img1 from "../assets/2022WTH/img1.jpeg"
import img2 from "../assets/2022WTH/img2.jpeg"
import img3 from "../assets/2022WTH/img3.jpeg"
import img4 from "../assets/2022WTH/img4.jpeg"
import img5 from "../assets/2022WTH/img5.jpeg"
import img6 from "../assets/2022WTH/img6.jpeg"
import img7 from "../assets/2022WTH/img7.jpeg"
import img8 from "../assets/2022WTH/img8.jpeg"
import img9 from "../assets/2022WTH/img9.jpeg"


const PastWTH = () => {
    return (
        <div className={styles.main}>
            <h1>Check out the previous <br />What The Hacks!</h1>
            <div className={styles.projectContainer}>
                <Carousel />
            </div>
            <div className={styles.yearContainer}>
                <h3>2022 WTH</h3>
                <p>- A Brighter Future -</p>
                <div className={styles.gallery}>
                    <img src={img1} alt=""/>
                    <img src={img2} alt=""/>
                    <img src={img3} alt=""/>
                    <img src={img4} alt=""/>
                    <img src={img5} alt=""/>
                    <img src={img6} alt=""/>
                    <img src={img7} alt=""/>
                    <img src={img8} alt=""/>
                    <img src={img9} alt=""/>

                </div>
            </div>

        </div>
    );
}

export default PastWTH;