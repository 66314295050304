import styles from "../styles/sponsors.module.css"
import platinumGem from "../assets/platinumGem.svg"
import goldGem from "../assets/goldGem.svg"
import silverGem from "../assets/silverGem.svg"
import bronzeGem from "../assets/bronzeGem.svg"
import tiktokLogo from "../assets/SponsorLogos/TikTok-logo-RGB-Horizontal-white.png"
import espressifLogo from "../assets/SponsorLogos/Espressif_Logo.png"
import dbsLogo from "../assets/SponsorLogos/DBSlogo_E1_4C RGB_r.svg"
import accentureLogo from "../assets/SponsorLogos/accenture-logo.png"
import govtechLogo from "../assets/SponsorLogos/govtechsg_logo.png"
import grainLogo from "../assets/SponsorLogos/grain_logo.png"
import istdLogo from "../assets/SponsorLogos/istd_logo.png"
import vieLogo from "../assets/SponsorLogos/vie_logo.png"
import imdaLogo from "../assets/SponsorLogos/IMDA_Logo.png"

const Sponsors = () => {
    return (
        <div className={styles.main}>
            <h1>Sponsors</h1>
            <div className={`${styles.container} ${styles.platinum}`}>
                <div className={styles.header}>
                    <h2>Platinum Tier</h2>
                    <img src={platinumGem} alt="" />
                </div>
                <div className={styles.subcontainer}>
                    <div className={styles.card}>
                        <a href="https://www.tiktok.com/about" target="_blank" rel="noreferrer"><img src={tiktokLogo} alt="Tiktok"/></a>
                    </div>
                </div>
            </div>
            <div className={`${styles.container} ${styles.gold}`}>
                <div className={styles.header}>
                    <h2>Gold Tier</h2>
                    <img src={goldGem} alt="" />
                </div>
                <div className={styles.subcontainer}>
                    <div className={styles.card}>
                        <a href="https://www.espressif.com/" target="_blank" rel="noreferrer"><img src={espressifLogo} alt="EspressIf"/></a>
                    </div>
                </div>
            </div>
            <div className={`${styles.container} ${styles.silver}`}>
                <div className={styles.header}>
                    <h2>Silver Tier</h2>
                    <img src={silverGem} alt="" />
                </div>
                <div className={styles.subcontainer}>
                    <div className={styles.card}>
                        <a href="https://www.dbs.com.sg/index/default.page" target="_blank" rel="noreferrer"><img src={dbsLogo} alt="DBS"/></a>
                    </div>
                </div>
            </div>
            <div className={`${styles.container} ${styles.bronze}`}>
                <div className={styles.header}>
                    <h2>Bronze Tier</h2>
                    <img src={bronzeGem} alt="" />
                </div>
                <div className={styles.subcontainer}>
                    <div className={styles.card}>
                        <a href="https://www.accenture.com/" target="_blank" rel="noreferrer"><img src={accentureLogo} alt="Accenture"/></a>
                    </div>
                    <div className={styles.card}>
                        <a href="https://grain.com.sg/" target="_blank" rel="noreferrer"><img src={grainLogo} alt="Grain"/></a>
                    </div>
                    <div className={styles.card}>
                        <a href="https://www.tech.gov.sg/" target="_blank" rel="noreferrer"><img src={govtechLogo} alt="GovTech"/></a>
                    </div>
                    <div className={styles.card}>
                        <a href="https://www.imda.gov.sg/" target="_blank" rel="noreferrer"><img src={imdaLogo} alt="IMDA"/></a>
                    </div>
                    
                </div>
            </div>
            <div className={`${styles.container} ${styles.sutdContainer}`}>
                <div className={styles.header}>
                    <h2>Supported By</h2>
                </div>
                <div className={styles.subcontainer}>
                    <div className={styles.card}>
                        <a href="https://istd.sutd.edu.sg/" target="_blank" rel="noreferrer"><img src={istdLogo} alt="ISTD"/></a>
                    </div>
                    <div className={styles.card}>
                        <a href="https://www.sutdvie.com" target="_blank" rel="noreferrer"><img src={vieLogo} alt="VIE"/></a>
                    </div>
                </div>
            </div>
            <h1>- More to be announced soon! -</h1>
        </div>
    );
}

export default Sponsors;