import { useState } from "react";
import Popup from "reactjs-popup";
import styles from "../styles/contactmodal.module.css"

const ContactModal = ({
    openModal,
    setOpenModal,
    error,
    errorMsg
}) => {

    const closeModal = () => setOpenModal(false);
    return (
        <Popup
            open={openModal}
            closeOnDocumentClick
            onClose={closeModal}
            modal
            overlayStyle={{background: "rgb(0,0,0,0.6)"}}
            >
            <div className={styles.modal}>
                <h2>{!error ? "Thank you!" : "Error!"}</h2>
                <p>{!error ? "Your message has been received. We will get back to you shortly via email." : errorMsg}</p>
                <button onClick={closeModal}>Close</button>
                
            </div>

        </Popup>
    );
}

export default ContactModal;