import { useEffect, useState } from "react";
import styles from "../styles/faq.module.css"
import { FaCaretDown } from "react-icons/fa";


const AccordionItem = ({
    item,
    selected
}) => {
    const [active, setActive] = useState(false);

    useEffect(() => {
        setActive(false);
    }, [selected])
    return (
        <div className={`${(active) ? styles.open : ""} ${styles.accordion}`}>
            <button onClick={() => setActive(!active)}>
                <span>{item.question}</span>
                <div className={styles.icon}>
                    <FaCaretDown />
                </div>
            </button>
            <div className={`${(active) ? styles.open : ""} ${styles.accordionContent}`}>
                <p>{item.answer}</p>
            </div>
        </ div>
    );
}

export default AccordionItem;