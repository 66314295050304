import styles from "../styles/about.module.css"
import moneyIcon from "../assets/cashPrize_icon.svg"
import studentsIcon from "../assets/everyone_icon.svg"
import skillsIcon from "../assets/skills_icon.svg"
import circularEconomyIcon from "../assets/Circular Economy.svg"
import inclusivityIcon from "../assets/Inclusivity Tech.svg"
import aiIcon from "../assets/AI-enabled IoT.svg"
import fintechIcon from "../assets/Financial Tech.svg"

const About = () => {
    return (
        <div className={styles.main}>
            <div className={styles.iconContainer}>
                <div className={styles.iconItem}>
                    <img src={moneyIcon} alt="" />
                    <h3>$6400 Prize Pool</h3>
                </div>
                <div className={styles.iconItem}>
                    <img src={studentsIcon} alt="" />
                    <h3>Open To All Students</h3>
                </div>
                <div className={styles.iconItem}>
                    <img src={skillsIcon} alt="" />
                    <h3>All Skill Levels Welcome</h3>
                </div>
            </div>
            <div className={styles.container}>
                <span className={styles.borderDeco}></span>
                <span className={styles.borderDeco}></span>
                <div className={styles.header}>
                    <div className={styles.headerLeft}>
                        <h1>What The Hack?</h1>
                    </div>
                    <div className={styles.headerRight}>
                        <h3>3-5 Pax Per Group <span>|</span> 2 Days <span>|</span> LOTS of Code and Fun!</h3>
                    </div>
                </div>
                <div className={styles.content}>
                    <p>SUTD's premier hackathon returns for its 5th iteration! <span>What The Hack</span> is a hardware and software hackathon, where engineers, creators and designers collaborate to make innovative solutions to meaningful real-world problems. <span>All in 2 Days.</span>
                        <br /><br />
                        This year's theme is <span>A Better World</span>, of which there will be four problem themes related to this overarching theme:
                    </p>
                    <div className={styles.problemContainer}>
                        <div className={styles.problemItem}>
                            <img src={circularEconomyIcon} alt="" />
                            <div className={styles.problemContent}>
                                <h3>Circular Economy & Sustainable Living</h3>
                                <p>How can workplaces, schools and homes be optimized to better sustainability?</p>
                            </div>
                        </div>
                        <div className={styles.problemItem}>
                            <img src={inclusivityIcon} alt="" />
                            <div className={styles.problemContent}>
                                <h3>Inclusivity & Accessibility Tech</h3>
                                <p>How can we ensure that advancements in tech remain accessible to all?</p>
                            </div>
                        </div>
                        <div className={styles.problemItem}>
                            <img src={aiIcon} alt="" />
                            <div className={styles.problemContent}>
                                <h3>AI-enabled Internet-of-Things</h3>
                                <p>How can AI and IoT be applied to create seamless and adaptive human-digital interactions?</p>
                            </div>
                        </div>
                        <div className={styles.problemItem}>
                            <img src={fintechIcon} alt="" />
                            <div className={styles.problemContent}>
                                <h3>Financial Technologies</h3>
                                <p>How can new technologies in digital wallets enable new and trustworthy user experiences?</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;