import styles from "../styles/banner.module.css"
import banner from "../assets/Backgrounds/banner.png"
import MainLogo from "../assets/MainLogo.png"
import { useCallback } from "react";
import Particles from "react-particles";
import { loadFull } from "tsparticles";
import Countdown from "../components/Countdown";

const Banner = () => {
    const options = {
        name: "Fireflies",
        particles: {
            number: {
                value: 160,
                density: {
                    enable: true,
                },
            },
            color: {
                value: "#ffffff",
            },
            shape: {
                type: "square",
            },
            opacity: {
                value: {
                    min: 0.1,
                    max: 0.6,
                },
                animation: {
                    enable: true,
                    speed: 0.7,
                    sync: false,
                },
            },
            size: {
                value: {
                    min: 1,
                    max: 3,
                },
            },
            move: {
                enable: true,
                speed: {
                    min: 0.1,
                    max: 0.8,
                },
            },
        },

    };

    const particlesInit = useCallback(async engine => {
        console.log(engine);
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async container => {
        await console.log(container);
    }, []);
    return (
        <div className={styles.main}>
            <div className={styles.bg}>
                <Particles
                    id="tsparticles"
                    init={particlesInit}
                    loaded={particlesLoaded}
                    options={options} />
                <img src={banner} alt="" />

            </div>
            <div className={styles.banner}>
                <img src={MainLogo} alt="" className={styles.mainLogo} />
                <h2><span className={styles.underline}>A Better World</span><br />26th - 27th August, 2023 <br />Singapore University of Technology and Design<br/></h2>
                <p>- The event has started! -</p>
                <Countdown />
            </div>
        </div>
    );
}

export default Banner