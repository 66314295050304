import styles from "../styles/main.module.css"
import NavBar from "../components/NavBar";
import NavBarMobile from "../components/NavBarMobile";
import About from "../components/About";
import Banner from "../components/Banner";
import { useRef } from "react";
import Prizes from "../components/Prizes";
import Schedule from "../components/Schedule";
import Sponsors from "../components/Sponsors";
import Faq from "../components/Faq";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import PastWTH from "../components/PastWTH";

const Main = () => {
    const bannerRef = useRef(null);
    const aboutRef = useRef(null);
    const prizesRef = useRef(null);
    const scheduleRef = useRef(null);
    const sponsorsRef = useRef(null);
    const faqRef = useRef(null);
    const pastWTHRef = useRef(null);

    return (
        <div className={styles.main}>
            <NavBar {...{aboutRef, prizesRef, scheduleRef, sponsorsRef, faqRef, bannerRef, pastWTHRef}}/>
            <NavBarMobile {...{aboutRef, prizesRef, scheduleRef, sponsorsRef, faqRef, bannerRef, pastWTHRef}}/>
            <div ref={bannerRef}>
                <Banner />
            </div>
            <div ref={aboutRef}>
                <About />
            </div>   
            <div ref={prizesRef}>
                <Prizes />
            </div>
            <div ref={scheduleRef}>
                <Schedule />
            </div>
            <div ref={sponsorsRef}>
                <Sponsors />
            </div>
            <div ref={pastWTHRef}>
                <PastWTH />
            </div>
            <div ref={faqRef}>
                <Faq />
            </div>
            <div>
                <Contact />
            </div>
            <div>
                <Footer />
            </div>
        </div>
    );
}

export default Main;